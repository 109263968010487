import isBefore from 'date-fns/is_before'
import parse from 'date-fns/parse'
import * as Sentry from '@sentry/browser'
import { clearAccessToken, setAccessToken } from '@/auth'
import * as storage from '@/storage'
import api from '@/api'
import axios from 'axios'

import { hashSHA1 } from '@/utils/hash'

const currency_map = {
    usd: '＄',
    jpy: '￥',
    eur: '€',
}

const state = {
    user: {},
    subscription: {},
    company: null,
    activity: [],
    mode: null,
}

const mutations = {
    RESET(state) {
        state.user = {}
        state.subscription = {}
        state.company = null
        state.activity = []
        clearAccessToken()
    },

    SET_CURRENT_USER(state, data) {
        state.user = data ? data : {}

        Sentry.getCurrentScope().setUser(state.user)

        if (!data || data == {}) {
            clearAccessToken()
        }
    },
    SET_COMPANY(state, data) {
        state.company = data
    },

    SET_SUBSCRIPTION(state, data) {
        state.subscription = data
    },

    SET_ACTIVITY(state, data) {
        state.activity = data || []
    },
    SET_MODE(state, data) {
        state.mode = data || null
    },
}

const getters = {
    currency({ user }) {
        return currency_map[user.currency]
    },
    signed_in(state) {
        return !!state.user.id
    },
    has_context(state) {
        return state.user.origin && state.user.origin.context_id
    },
    subscribed(state) {
        return state.user.is_subscribed
    },
    is_subscribed(state) {
        return state.user.is_subscribed
    },
    on_trial(state) {
        return state.user.is_on_trial
    },
    is_on_free({ user }, { is_subscribed }) {
        return !is_subscribed && isBefore(parse(user.created_at), parse('2021-10-30'))
    },
    subscription(state, getters) {
        return getters.is_subscribed && !getters.on_trial && state.user.subscription
    },
    subscribed_pro(state, getters) {
        return getters.subscription?.name === 'pro'
    },
    subscribed_business(state, getters) {
        return getters.subscription?.name === 'business'
    },
    subscribed_standard(state, getters) {
        return getters.subscription?.name === 'standard'
    },
    subscribed_onecoin(state, getters) {
        return getters.subscription?.name === 'onecoin'
    },
    subscribed_smb(state, getters) {
        return getters.subscription?.name === 'smb'
    },
    projects_on_plan(state) {
        return state.user.subscribed_to_tier3 ? 5 : state.user.subscribed_to_tier2 ? 3 : 1
    },
    subscribed_mailer(state) {
        return state.user.subscribed_to_mailer
    },
    allowed_to_create_project(state, getters) {
        return getters.projects_on_plan > state.user?.projects_count
    },
    venues_on_plan(state) {
        return state.user.venues_on_plan
    },
    is_kinchaku_staff(state) {
        return state.user.origin?.acl >= 9
    },
    is_stripe_customer(state) {
        return !!state.user.stripe_id
    },
    role(state) {
        return state.user.user_role
    },
    shadow_role(state) {
        return state.user.shadow_role
    },
    has_owner_rights(state) {
        return !state.user.origin || state.user.user_role === 'owner'
    },
    has_member_rights(state, getters) {
        return (
            getters.has_context &&
            (state.user.user_role === 'owner' ||
                state.user.user_role === 'member' ||
                state.user.user_role === 'editor')
        )
    },
    is_squareup_mode({ mode }) {
        return mode === 'squareup'
    },
    workspace_flags({ user }) {
        return (user.settings && user.settings.flags) || {}
    },
    staff_venue_mode({ user }, getters, { venues }) {
        return !!(user.user_role === 'operator' && venues?.list?.length)
    },
    venue_manager_mode({ user }) {
        return user.user_role === 'venue-manager'
    },
    editor_mode({ user }) {
        return user.user_role === 'editor'
    },
    is_full_company_business_info({ company }, { is_squareup_mode }) {
        if (!company) return false

        let additionalFields = true

        if (is_squareup_mode) {
            additionalFields = !!(
                company.business_type &&
                company.state &&
                company.city &&
                company.address &&
                company.other &&
                company.first_name &&
                company.last_name
            )
        }

        return !!(
            company.name &&
            company.zip &&
            company.country &&
            company.business_category &&
            additionalFields
        )
    },
}

const actions = {
    signUp({ dispatch }, user) {
        return api
            .post('/auth/register', { user })
            .then(response => {
                if (!response.token) {
                    return dispatch('handleError', response)
                }
                setAccessToken(response.token)
                return true
            })
            .catch(e => dispatch('handleError', e))
    },

    clearSession({ commit }) {
        commit('RESET')
    },

    signOut({ dispatch }) {
        dispatch('clearSelectedVenue')
        dispatch('clearVenuesList')

        api.post('/auth/logout', {})
    },

    signIn(context, user) {
        if (context.getters.signed_in) return context.dispatch('getUser')

        return api
            .post('/auth/login', { user })
            .then(response => {
                if (response.tfa) {
                    return false
                }
                setAccessToken(response.token)
                return true
            })
            .catch(e => context.dispatch('handleError', e))
    },

    getUser({ commit, dispatch, state }) {
        return api
            .get('/context')
            .then(async ({ data }) => {
                if (!state['company'] && data?.is_workspace) await dispatch('getUserCompany')
                if (data.business_model === 'squareup') commit('SET_MODE', 'squareup')

                commit('SET_CURRENT_USER', data)

                return data
            })
            .catch(e => dispatch('handleError', e))
    },

    updateAccount({ commit, dispatch }, account) {
        return api
            .patch('/account', {
                account,
            })
            .then(({ data }) => {
                commit('SET_CURRENT_USER', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },

    changePassword({ dispatch }, account) {
        return api
            .patch('/account/password', {
                account,
            })
            .catch(e => dispatch('handleError', e))
    },

    deleteAccount({ dispatch }) {
        return api.delete('/account').catch(e => dispatch('handleError', e))
    },

    updateLanguage({ dispatch }, language) {
        return api
            .patch('/account/language', { language })
            .then(() => dispatch('getUser'))
            .catch(e => dispatch('handleError', e))
    },

    getUserCompany({ commit, dispatch }) {
        return api
            .get('/user/company')
            .then(({ company }) => {
                commit('SET_COMPANY', company)
                return company
            })
            .catch(e => dispatch('handleError', e))
    },

    updateUserCompany({ dispatch }, company) {
        return api.put('/user/company', { company }).catch(e => dispatch('handleError', e))
    },

    switchContext({ dispatch }, context_id) {
        return api
            .post(`/switch/${context_id}`, {})
            .then(() => {
                try {
                    // reload other browser windows with same origin
                    storage.local.setItem(
                        'kinchaku-communication-channel',
                        JSON.stringify({
                            command: 'switch-context',
                            uid: new Date().getTime() + Math.random(),
                        })
                    )
                    storage.local.removeItem('kinchaku-communication-channel')
                } catch (e) {
                    // do nothing
                }
            })
            .catch(e => dispatch('handleError', e))
    },

    getSubscription({ commit, dispatch }) {
        commit('SET_SUBSCRIPTION', {})
        return api
            .get('/billing/stripe')
            .then(({ subscription }) => {
                commit('SET_SUBSCRIPTION', subscription)
                return subscription
            })
            .catch(e => dispatch('handleError', e))
    },

    checkInviteCode(state, code) {
        return api.get(`/invites/${code}`)
    },

    acceptInviteCode({ dispatch }, code) {
        return api.patch(`/invites/${code}/accept`).catch(e => {
            if (
                e === 401 ||
                (e.response?.data?.errors?.message || '').startsWith('Please sign in as')
            ) {
                return { redirect: true }
            }

            return dispatch('handleError', e)
        })
    },

    getPendingInvitations({ dispatch }) {
        return api
            .get('/invites/list/pending')
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    getActivity({ dispatch }, { pagination, filter }) {
        return api
            .get('/activity', { params: { ...pagination, filter } })
            .catch(e => dispatch('handleError', e))
    },

    getSessions({ dispatch }, pagination) {
        return api
            .get('/sessions', { params: { ...pagination } })
            .catch(e => dispatch('handleError', e))
    },

    getOperatorSessions({ dispatch }, id) {
        return api //params: { ...pagination }
            .get(`/sessions/${id}`, {})
            .catch(e => dispatch('handleError', e))
    },

    getCurrentSession({ dispatch }) {
        return api.get('/sessions/current').catch(e => dispatch('handleError', e))
    },

    renameSession({ dispatch }, session) {
        return api
            .patch(`/sessions/${session.id}`, { session })
            .catch(e => dispatch('handleError', e))
    },

    revokeSession({ dispatch }, session) {
        return api.delete(`/sessions/${session.id}`, {}).catch(e => dispatch('handleError', e))
    },

    setAccountMode({ commit }, mode) {
        commit('SET_MODE', mode)
    },

    setAccountSquareUpMode({ commit }) {
        commit('SET_MODE', 'squareup')
    },

    async isCompromisedPassword({ dispatch }, password) {
        const passSha1 = await hashSHA1(password).then(hash => hash.toUpperCase())
        const range = passSha1.slice(0, 5)

        return axios
            .get(`https://api.pwnedpasswords.com/range/${range}`)
            .then(result => {
                const hashArray = result.data.split('\r\n')
                return hashArray.some(item => {
                    const [hash] = item.split(':')
                    return `${range}${hash}`.toUpperCase() === passSha1
                })
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations,
}
