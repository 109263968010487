<script>
import { camelCase } from 'lodash'
import {
    mdiAccountCircleOutline,
    mdiAccountMultipleOutline,
    mdiBookOpenPageVariantOutline,
    mdiBullhornOutline,
    mdiCalendarClockOutline,
    mdiCashMultiple,
    mdiCogOutline,
    mdiContentCopy,
    mdiCreation,
    mdiEyeCircle,
    mdiEyeOff,
    mdiFileDocumentOutline,
    mdiHelpCircleOutline,
    mdiInformationOutline,
    mdiMagnify,
    mdiMapMarkerOutline,
    mdiMessageAlertOutline,
    mdiOpenInNew,
    mdiPaletteSwatchOutline,
    mdiRadar,
    mdiReceiptTextOutline,
    mdiSecurity,
    mdiSendOutline,
    mdiSquareEditOutline,
    mdiTextBoxOutline,
    mdiViewDashboardOutline,
    mdiViewGridPlusOutline,
    mdiUpdate,
    mdiWebOff,
} from '@mdi/js'

export default {
    name: 'KIcon',
    computed: {
        icons_list() {
            return {
                mdiAccountCircleOutline,
                mdiAccountMultipleOutline,
                mdiBookOpenPageVariantOutline,
                mdiBullhornOutline,
                mdiCalendarClockOutline,
                mdiCashMultiple,
                mdiCogOutline,
                mdiContentCopy,
                mdiCreation,
                mdiEyeCircle,
                mdiEyeOff,
                mdiFileDocumentOutline,
                mdiHelpCircleOutline,
                mdiInformationOutline,
                mdiMagnify,
                mdiMapMarkerOutline,
                mdiMessageAlertOutline,
                mdiOpenInNew,
                mdiPaletteSwatchOutline,
                mdiRadar,
                mdiReceiptTextOutline,
                mdiSecurity,
                mdiSendOutline,
                mdiSquareEditOutline,
                mdiTextBoxOutline,
                mdiViewDashboardOutline,
                mdiViewGridPlusOutline,
                mdiUpdate,
                mdiWebOff,
            }
        },
        icon() {
            const iconText = camelCase(this.$slots.default[0].text)
            return this.icons_list[iconText] || iconText
        },
    },
}
</script>

<template>
    <v-icon v-bind="$attrs" v-on="$listeners">{{ icon }}</v-icon>
</template>
